import React from "react";
import Layout from "../../components/Layout";

export default function bibliografiaImpresa() {
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div
                        className="services_banner"
                        style={{backgroundImage: `url(../../images/sector_educativo/2.jpg)`}}
                    >
                        <h1>Bibliografía Impresa</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Descripción</h1>
                        <p className="services_section_1__paragraph">
                            Somos líderes en la distribución de libro impreso para todas las áreas del conocimiento,
                            publicados por los sellos más importantes del mundo editorial. Somos especialistas en
                            planeación de adquisiciones lo que permite optimizar presupuestos anuales.
                        </p>
                        <p><strong>Beneficios</strong></p>
                        <ul style={{color: '#000000'}}>
                            <li>Relación con más de 3,000 casas editoriales nacionales e internacionales</li>
                            <li>Entrega de cotizaciones competitivas acorde a los precios del mercado</li>
                            <li>Fuerte experiencia en la importación de libros extranjeros que permite tiempos de
                                entrega óptimos
                            </li>
                            <li>Distribuimos materiales técnicos y científicos para todas las áreas del conocimiento
                            </li>
                            <li>Incluye títulos para divulgación científica, interés general y libros de texto</li>
                            <li>Contamos con material actualizado de entrega inmediata</li>
                            <li>Tenemos especialistas que permiten sugerir sustitutos en libros fuera de prensa</li>
                            <li>Entregamos a cualquier parte de América Latina</li>
                        </ul>
                    </div>
                </div>
            </div>
        </Layout>
    )
}